import { ApolloClient, InMemoryCache, HttpLink, gql } from '@apollo/client';

const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://dashboard.boulevard.io/api/2020-01/87ee383f-8c0e-4a59-8738-ec2b4c2ebe21/client',
    headers: {
      Authorization: "Basic MjcwZGY1ZTMtZTk4NC00NmJhLTg3ZjAtY2NkYmYyMzQxYjliOg==",
    },
  }),
  cache: new InMemoryCache(),
});

const LOCATIONS_QUERY = gql`
  query {
      business {
        locations(first: 1) {
          edges {
            node {
              id
              name
              tz
            }
          }
        }
      }
  }
`

async function getLocationId() {
  const resp = await client.query({ query: LOCATIONS_QUERY })
  const locationId = resp.data.business.locations.edges[0].node.id;
  return locationId;
}

const CART_FIELDS = `
id
clientInformation {
  phoneNumber
  firstName
  lastName
  email
}
availableCategories {
  id
  name
  availableItems {
    id
    name
    listPriceRange {
      min
      max
      variable
    }
  }
}
selectedItems {
  id
  price
  lineTotal
  ... on CartBookableItem {
    selectedStaffVariant {
      id
    }
    item {
      id
      name
      ... on CartAvailableBookableItem {
        staffVariants {
          id
          staff {
            displayName
            role {
              name
            }
          }
        }
      }
    }
  }
  ... on CartPurchasableItem {
    item {
      id
      name
    }
  }
}
startTime
startTimeId
completedAt
location {
  tz
},
summary {
  deposit
  depositAmount
  taxAmount
  subtotal
  total
}
`;

const CART_QUERY = gql`
  query ($id: ID!) {
    cart (id: $id) {
      ${CART_FIELDS}
    }
  }
`

async function getCart(id) {
  const resp = await client.query({ query: CART_QUERY, variables: { id } });
  return resp.data.cart;
}

const CREATE_CART_MUTATION = gql`
  mutation CreateCart($locationId: String!) {
    createCart(input: { locationId: $locationId }) {
      cart {
        ${CART_FIELDS}
      }
    }
  }
`

async function createCart() {
  const locationId = await getLocationId();
  const resp = await client.mutate({
    mutation: CREATE_CART_MUTATION,
    variables: { locationId }
  });
  const cart = resp.data.createCart.cart;
  return cart;
}

const UPDATE_CART_MUTATION = gql`
  mutation UpdateCart($cart: UpdateCartInput!) {
    updateCart(input: $cart) {
      cart {
        ${CART_FIELDS}
      }
    }
  }
`

async function updateCart(cart) {
  const resp = await client.mutate({
    mutation: UPDATE_CART_MUTATION,
    variables: { cart }
  });
  return resp.data.updateCart.cart;
}

const ADD_CART_SELECTED_BOOKABLE_ITEM = gql`
  mutation AddCartSelectedBookableItem($input: AddCartSelectedBookableItemInput!) {
    addCartSelectedBookableItem(input: $input) {
        cart {
          ${CART_FIELDS}
        }
    }
}
`

async function addBookableItem(cartId, itemId) {
  const resp = await client.mutate({
    mutation: ADD_CART_SELECTED_BOOKABLE_ITEM,
    variables: {
      input: {
        id: cartId,
        itemId
      }
    }
  });
  return resp.data.addCartSelectedBookableItem.cart;
}

const REMOVE_CART_SELECTED_ITEM = gql`
  mutation RemoveCartSelectedItem($input: RemoveCartSelectedItemInput!) {
    removeCartSelectedItem(input: $input) {
      cart {
        ${CART_FIELDS}
      }
    }
}
`

async function removeSelectedItem(cartId, itemId) {
  const resp = await client.mutate({
    mutation: REMOVE_CART_SELECTED_ITEM,
    variables: {
      input: {
        id: cartId,
        itemId
      }
    }
  });
  return resp.data.removeCartSelectedItem.cart;
}

const ADD_CART_SELECTED_PURCHASABLE_ITEM = gql`
  mutation AddCartSelectedPurchasableItem($input: AddCartSelectedPurchasableItemInput!) {
    addCartSelectedPurchasableItem(input: $input) {
        cart {
          ${CART_FIELDS}
        }
    }
}
`

async function addPurchasableItem(cartId, itemId) {
  const resp = await client.mutate({
    mutation: ADD_CART_SELECTED_PURCHASABLE_ITEM,
    variables: {
      input: {
        id: cartId,
        itemId
      }
    }
  });
  return resp.data.addCartSelectedPurchasableItem.cart;
}

const UPDATE_CART_SELECTED_BOOKABLE_ITEM = gql`
  mutation UpdateCartSelectedBookableItem($input: UpdateCartSelectedBookableItemInput!) {
    updateCartSelectedBookableItem(input: $input) {
        cart {
          ${CART_FIELDS}
        }
    }
}
`

async function updateSelectedBookableItem(updateInput) {
  const resp = await client.mutate({
    mutation: UPDATE_CART_SELECTED_BOOKABLE_ITEM,
    variables: {
      input: updateInput
    }
  });
  return resp.data.updateCartSelectedBookableItem.cart;
}


const GET_BOOKABLE_TIMES = gql`
  query GetAvailableTimes($id: String!, $searchDate: String!, $tz: String!) {
    cartBookableTimes(id: $id, searchDate: $searchDate, tz: $tz) {
      id
      startTime
    }
  }
`

async function getBookableTimes(id, searchDate, tz) {
  const resp = await client.query({ query: GET_BOOKABLE_TIMES, variables: { id, searchDate, tz } });
  return resp.data.cartBookableTimes;
}

const RESERVE_CART_BOOKABLE_ITEMS = gql`
mutation ReserveCartBookableItems($input: ReserveCartBookableItemsInput!) {
    reserveCartBookableItems(input: $input) {
        cart {
          ${CART_FIELDS}
        }
    }
}
`

async function reserveCartBookableItems(cartId, bookableTimeId) {
  const resp = await client.mutate({
    mutation: RESERVE_CART_BOOKABLE_ITEMS,
    variables: {
      input: {
        id: cartId,
        bookableTimeId
      }
    }
  });
  return resp.data.reserveCartBookableItems.cart;
}

const ADD_CART_CARD_PAYMENT_METHOD = gql`
  mutation AddCartCardPaymentMethod($input: AddCartCardPaymentMethodInput!) {
    addCartCardPaymentMethod(input: $input) {
      cart {
        ${CART_FIELDS}
      }
    }
  }
`

async function addCartCardPaymentMethod(id, token, select) {
  const resp = await client.mutate({
    mutation: ADD_CART_CARD_PAYMENT_METHOD,
    variables: {
      input: {
        id,
        token,
        select
      }
    }
  });
  return resp.data.addCartCardPaymentMethod.cart;
}

const CHECKOUT_CART = gql`
  mutation CheckoutCart($input: CheckoutCartInput!) {
    checkoutCart(input: $input) {
      appointments {
        appointmentId
        clientId
        forCartOwner
      }
      cart {
        ${CART_FIELDS}
      }
    }
}
`

async function checkoutCart(id) {
  const resp = await client.mutate({
    mutation: CHECKOUT_CART,
    variables: {
      input: {
        id
      }
    }
  });
  return resp.data.checkoutCart;
}

const exports = {
  client,
  getCart,
  createCart,
  updateCart,
  addBookableItem,
  removeSelectedItem,
  addPurchasableItem,
  updateSelectedBookableItem,
  getBookableTimes,
  reserveCartBookableItems,
  addCartCardPaymentMethod,
  checkoutCart
};

export default exports;
