import 'bulma/bulma.sass';
import './src/styles/main.css';

import React from 'react'
import { ApolloProvider } from '@apollo/client'
import BoulevardApi from './src/boulevard-api'

export const wrapRootElement = ({ element }) => (
    <ApolloProvider client={BoulevardApi.client}>
        {element}
    </ApolloProvider>
)
